<template>
  <div class="wrapper-inner">
    <div class="inner-content">
      <h1 class="header-big">Новинки</h1>
      <media-block
        :media="noveltyVideos"
        block-head="Видео"
        :size="8"
        :update-media="loadVideos"
      />
      <articles-block :data="articles" />
      <event-block :data="events" />
    </div>
  </div>
</template>

<script>
import { WOW } from "wowjs";
import MediaBlock from "@/components/page/media/MediaBlock";
import { mapActions, mapGetters } from "vuex";
import ArticlesBlock from "@/components/page/main/ArticlesBlock";
import EventBlock from "@/components/page/main/EventBlock";
export default {
  name: "Novelty",
  components: { EventBlock, ArticlesBlock, MediaBlock },
  data() {
    return {
      page: 1,
      pageEvent: 1
    };
  },
  computed: {
    ...mapGetters(["noveltyVideos", "articles", "events"])
  },
  methods: {
    ...mapActions(["getNoveltyVideos", "getArticles", "getPageEvents"]),
    loadVideos() {
      this.getNoveltyVideos({
        page: this.page,
        size: 8
      }).then(() => {
        this.page++;
      });
    },
    loadEvents() {
      this.getPageEvents(this.pageEvent).then(() => {
        this.pageEvent++;
      });
    }
  },
  created() {
    this.loadVideos();
    this.getArticles({ page: 1, size: 16, sort: "NEW", sortType: "DESC" });
    this.loadEvents();
  },
  mounted() {
    new WOW({ offset: 140 }).init();
  }
};
</script>

<style scoped></style>
